export default function () {

  if(screen.width > 1200){
    return;
  }

  let trigger = document.querySelectorAll('[data-trigger]');
  let previousActionDone = true;

  trigger.forEach((item)=>{
    let triggerType = item.dataset.trigger;
    let target = document.querySelector(item.dataset.target);  
      
    item.addEventListener('click', ()=>{
      
      if(!previousActionDone) {
        return;
      }
      
      previousActionDone = false;
      
      switch (triggerType) {
        case 'collapse':
          if (target.classList.contains('show')) {
            collapseHide(target);
            item.classList.remove('is-active');
          } else {
            collapseShow(target);
            item.classList.add('is-active');
          }
          break;
      }

    })
  })


  function collapseShow(collapse){
    collapse.classList.remove('collapse');
    
    let height = collapse.scrollHeight;
    
    collapse.classList.add('collapsing');
    
    const complete = function(){
      setTimeout(()=>{
        collapse.classList.remove('collapsing');
        collapse.classList.add('collapse', 'show');    
        collapse.style.height = "";  
        previousActionDone = true;
      }, 0)
    }
    
    setTimeout(()=>{
      collapse.style.height = `${height}px`;
      collapse.addEventListener('transitionend', ()=>{
        complete()    
      })
    }, 0)
  }


  function collapseHide(collapse){
    
    let height = collapse.scrollHeight;
    collapse.style.height = `${height}px`;  

    collapse.classList.remove('show', 'collapse');
    collapse.classList.add('collapsing');
    
    const complete = function(){
      setTimeout(()=>{
        collapse.classList.remove('collapsing', 'show');
        collapse.classList.add('collapse');      
        collapse.style.height = "";  
        previousActionDone = true;
      }, 0)
    }
    
    setTimeout(()=>{
      collapse.style.height = 0;
      
      collapse.addEventListener('transitionend', ()=>{
        complete()    
      })
    }, 0)
  }

}