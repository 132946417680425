export default function () {

  if(screen.width > 1200){
    return;
  }
  
  let body = document.querySelector('body')
  let menuTrigger = document.querySelector('.js-menuTrigger')
  let toggleMenus = document.querySelectorAll('.js-toggleMenu')

  if(menuTrigger) {
    menuTrigger.addEventListener('click', ()=>{
      body.classList.toggle('is-active')
    })

    toggleMenus.forEach(item => {
      item.addEventListener('click', ()=> {
        menuTrigger.click();
      })
    });
  }
}